import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/Layout';
import { Section } from '../../components/Section';
import { Calculator, CalculatorAside } from '../../components/Calculator';
import Testimonials from '../../components/Testimonials';
import SectionTop from '../../components/SectionTop';
import staticData from '../index-data.json';
import './index.scss';

const PayForEssayWriting = () => (
  <Layout className="IndexPage PayForEssayWriting">
    <Helmet>
      <title>Pay Someone to Write Essay That Deserves the Highest Score | Ca.CustomWritings.com</title>
      <meta
        name="description"
        content="Have no idea whom to pay for essay? Visit our website, have a look at the services we offer, and make sure that we’re the best way out for your troubles. Don’t waste time - place your order beforehand and get help at the lowest possible price."
      />
      <link rel="alternate" href="https://www.customwritings.com/pay-essays.html" hrefLang="x-default" />
      <link rel="alternate" href="https://www.customwritings.com/pay-essays.html" hrefLang="en-us" />
      <link rel="alternate" href="https://ca.customwritings.com/pay-for-essay-writing.html" hrefLang="en-ca" />
      <link rel="canonical" href="https://ca.customwritings.com/pay-for-essay-writing.html" />
    </Helmet>

    <SectionTop>
      <h1 className="Section-top__content__title">Pay for your essay and get timely, high-quality academic help</h1>
      <span className="Section-top__content__subtitle" />
      <span className="Section-top__content__description">Our best writers are ready to assist you with more than 70 disciplines, 24/7</span>
      <a rel="nofollow" href="https://www.customwritings.com/dashboard/orders/create/" className="button">Buy an essay</a>
    </SectionTop>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-1">Looking for where to pay for essay online? We can help!</h2>
      <p>
        Essay writing is an integral part of college and high school courses in most disciplines. It not only
        evaluates how good you are at expressing your thoughts in writing but also helps your teachers and professors
        determine whether you understood the main points made in class and whether you can think independently.
        Throughout your time in university, you’ll have to write at least one essay every week, more if you take a few
        writing-heavy courses. This means that at least occasionally you’ll have too much on your plate to be able to
        deal with it all in a timely manner. In such situations, it may be the lesser of two evils to pay someone to
        write essay so that you can fully concentrate on other assignments. However, you should always be very careful
        when choosing whom you pay for essay writing, as not all academic assistance services are equally deserving of
        your trust.
      </p>
    </Section>

    <Section className="Section-calculator Section--bg-blue">
      <h2 className="Section__heading">Calculate the price of your paper here</h2>
      <div className="calculator-wrapper">
        <Calculator />
        <CalculatorAside />
      </div>
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-2">CustomWritings.com: Pay for essay writing and forget about your problems</h2>
      <p>
        Even if it’s not your first time resorting to the assistance of online writing services, you still probably
        feel disoriented when facing the multitude of offers you see at every turn. Why should you choose our agency
        to pay to write essay for you? It’s a legitimate question, so here are a few things you should consider:
      </p>
      <ul>
        <li>
          Our service is affordable. With prices starting as low as $10 per page, our assistance is easily available
          to anyone who’s serious about improving their academic record. As our fees largely depend on how quickly you
          want us to complete the job, you can save a lot by placing your order early on.
        </li>
        <li>
          We are available 24/7. If you want to pay for essay online, you can contact us whenever it’s convenient for
          you – our customer support team will immediately accept your order and start looking for a suitable writer.
          As our employees come from multiple time zones, some of them are ready to start working even if you call us
          in the middle of the night.
        </li>
        <li>
          We offer multiple customization options. You don’t have to just pay our essay writers to do your assignment
          and hope for the best—you can describe exactly what you need in minute detail. Specify what kind of writer
          you want, what additional comments and materials you need, and we’ll follow your instructions to the letter.
        </li>
      </ul>
    </Section>

    <Section className="Section Section-testimonials Section--bg-blue">
      <h2 className="Section__heading">What customers say about us</h2>
      <Testimonials testimonials={staticData.testimonials} />
    </Section>

    <Section className="Section Section-seo">
      <h2 className="Section__heading Section__heading--bubble-3">The best place to pay for an essay in Canada</h2>
      <p>
        Canadian students have it especially rough when it comes to using academic assistance services. You can’t just
        pay for essay writing anywhere, as most of these companies specialize in working for American students.
        Although Canadian and American educational systems are similar, there are differences, and somebody unused to
        Canadian conventions can make many telling mistakes that are unlikely to reflect well on your grades.
        Therefore, when you pay for an essay, you have to make sure the service you’re dealing with has writers who
        are used to working with Canadian clients. With CustomWritings.com, you don’t have to worry about these
        details. Simply contact our customer support, say, “I want to pay someone to write my essay and be sure that
        he/she follows the conventions and formatting used in Canadian colleges.” We’ll find a writer who has
        experience with your chosen formatting style and, if you want, we’ll even assign a native speaker from Canada
        to work on your order.
      </p>
      <h2 className="Section__heading Section__heading--bubble-1">Use CustomWritings.com and feel confident about the results</h2>
      <p>
        Every so often, when a student contacts our customer support team, he/she says something along these lines:
        “I’m looking for someone to pay to write my essay, but I don’t feel comfortable with simply giving my money to
        a stranger and waiting for the results. Can you give me any guarantees? When I pay someone to do my essay, I
        want to be sure that I’ll receive it on time, and that it will be written exactly the way I want it.” This is
        quite a natural sentiment to have, which is why we’ve created a set of guarantees to put your mind at ease.
        Here’s what we promise:
      </p>
      <ul>
        <li>
          Originality. We guarantee that your paper will be written from scratch, and run extensive checks using
          powerful anti-plagiarism software to make sure of it.
        </li>
        <li>
          Confidentiality. We carefully protect the personal and financial data of our clients, and we don’t require
          much information to set up your account in the first place.
        </li>
        <li>
          Refunds. We’re ready to give you a full or partial refund in a variety of situations. You can find out the
          details on our money-back guarantee page.
        </li>
        <li>
          Free revisions. Ask for improvements within a week of receiving your paper, and we will revise it for free.
        </li>
      </ul>
      <p>
        As you can see, CustomWritings.com does everything to make the life of its customers easy and safe—so what are
        you waiting for?
      </p>
    </Section>
  </Layout>
);

export default PayForEssayWriting;
